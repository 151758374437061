import { Link } from 'gatsby';
import React from 'react';

// styles
const classes = {
  card: 'rounded overflow-hidden shadow-lg',
};

const Card = ({ title, summary, link = false, internal = false }) => {
  let linkContent;
  if (internal) {
    linkContent = <Link to={link}>{title}</Link>;
  } else {
    linkContent = <a href={link}>{title}</a>;
  }

  return (
    <Link to={link}>
        <div class={classes.card}>
          <div class="px-6 py-4 bg-blue-200">
              <div class="font-bold text-xl mb-2">{title}</div>
              <p class="text-black text-base">
              {summary}
              </p>
          </div>
          {/* <div class="px-6 pt-4 pb-2">
              <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#photography</span>
              <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#travel</span>
              <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#fall</span>
          </div> */}
        </div>
    </Link>
    
  );
};

export default Card;