import React from 'react';
import Card from '../../components/Card';

const InternalPostsSummary = ({ posts }) => {
  return (
    <div class="p-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-5">
      {posts.map((post) => (
        <Card
          key={post.node.fields.slug}
          title={post.node.frontmatter.title}
          summary={post.node.frontmatter.summary}
          link={post.node.fields.slug}
          internal
        />
      ))}
    </div>
    
  );
};

export default InternalPostsSummary;
