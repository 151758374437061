import { graphql } from 'gatsby';
import React from 'react';

import SEO from '../components/SEO';
import NotFound from './404';
import Blog from '../views/Blog'

const Index = ({ data }) => {
  const posts = data.allMarkdownRemark.edges;
  
  if (!posts || !posts.length) {
    return <NotFound />;
  }

  return (
    <React.Fragment>
      <SEO />
      <Blog metadata={data.site.siteMetadata} data={data}/>
    </React.Fragment>
  );
};

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        name
        title
        description
        about
        author
        github
        linkedin
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            summary
          }
        }
      }
    }
  }
`;
